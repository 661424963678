import {Col, Container, Row} from "react-bootstrap";
import classes from "../styles/components_styles/header.module.scss";
import React from "react";

import notification_img from '../images/notification_img.svg';
import getDate from "../Hooks/getDate";

function Notifications(props) {

    const date = getDate(27, 1);

    return (
        <Container className={classes.notifications_layout} status={props.status}>
            <Row>
                <Col xl={12} className={`${classes.notifications_blocks_green} ${classes.notifications_blocks}`}>
                    <Row>
                        <Col>
                            <div className={classes.notificationTextGreen}>
                                <p>Christmas discounts on Walmart !</p>
                            </div>
                        </Col>
                        <Col>
                            <img className={classes.notificationImg} src={notification_img}/>
                        </Col>
                    </Row>
                </Col>
                <Col xl={12}>
                    <p className={classes.notificationDate}>{date}</p>
                </Col>
            </Row>
            <Row>
                <Col xl={12} className={`${classes.notifications_blocks_black} ${classes.notifications_blocks}`}>
                    <Row>
                        <Col>
                            <h5 className={classes.notificationsTitle}>SHEIN</h5>
                        </Col>
                        <Col>
                            <div className={classes.notificationTextBlack}>
                                <p>New summer collection in <b>Shein</b></p>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col xl={12}>
                    <p className={classes.notificationDate}>July 12, 2022</p>
                </Col>
            </Row>
        </Container>
    );
}

export default Notifications;