import classes from '../styles/loginForm.module.scss';
import elements from "../styles/elements.module.scss";

import {Container} from "react-bootstrap";
import {MdLockOutline, MdPersonOutline} from "react-icons/md";
import {NavLink} from "react-router-dom";
import React from "react";
import {paths} from "../store/paths";
import BigLogo from "../components/BigLogo";

function LoginPage() {

    const login = React.createRef();
    const password = React.createRef();

    const loginArea = React.createRef();
    const passwordArea = React.createRef();

    let onClick = (e) => {
        loginArea.current.setAttribute("validation", "valid");
        passwordArea.current.setAttribute("validation", "valid");
        if(login.current.value == ""){
            loginArea.current.setAttribute("validation", "invalid");
            e.preventDefault();
        }
        if(password.current.value == ""){
            passwordArea.current.setAttribute("validation", "invalid");
            e.preventDefault();
        }
    }

    return (
        <Container className={elements.container_center}>
            <BigLogo title={"Login"}/>
            <div ref={loginArea} className={`${elements.input_area} ${classes.input_area}`}>
                <MdPersonOutline className={elements.input_logo}/>
                <input type="text" placeholder="login" ref={login}/>
            </div>

            <div ref={passwordArea} className={`${elements.input_area} ${classes.input_area}`}>
                <MdLockOutline className={elements.input_logo}/>
                <input type="password" placeholder="password" ref={password}/>
            </div>

            <NavLink onClick={onClick} to={paths.homePage} className={`${classes.button} ${elements.button} ${elements.btn_fill}`}>Login</NavLink>
        </Container>
    );
}

export default LoginPage;