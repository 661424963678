export const CHANGE_LOGIN = 'CHANGE-LOGIN';
export const CHANGE_NAME = 'CHANGE-NAME';

export const onLoginChangeActionCreator = (login) => {
    return{
        type: CHANGE_LOGIN,
        newLogin: login
    }
}

export const onNameChangeActionCreator = (name) => {
    return{
        type: CHANGE_NAME,
        newName: name
    }
}