import React, {useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import NavigationMenu from "../components/NavigationMenu";
import elements from "../styles/elements.module.scss";
import classes from "../styles/assistantPage.module.scss";
import HeaderContainer from "../pageContainers/HeaderContainer";
import assistant from "../images/assistant.svg";
import user from "../images/avatar.svg";
import sendMessageIcon from "../images/sendMessageIcon.svg";
import messageAssistant from "../images/messageAssistant.svg";

function AssistantPage() {

    const [newMessageText, setNewMessageText] = useState("");
    const [availableCommands, setAvailableCommands] = useState("Start");

    const [messages, setMessages] = useState([
        <>
            <Row className={classes.messagesArea}>
                <Col xl={1} lg={1} className={classes.assistantIcon}>
                    <img src={messageAssistant}/>
                </Col>
                <Col xl={10} lg={10}>
                    <p className={classes.assistantMessage}>Write <b>Start</b> or press the button to start the chat</p>
                    <br/>
                </Col>
                <Col xl={1} lg={1}>

                </Col>
            </Row>
        </>
    ]);

    let Messages = () => {
        return messages;
    }

    let addMessage = (newMessages) => {
        let updateMessages = [...messages, newMessages];
        setMessages(updateMessages);
    }

    let Button = () => {
        if(availableCommands == "Start"){
            return <button onClick={startChat} className={classes.quickMessageButton}>Start</button>;
        }
        if(availableCommands == "Set up"){
            return <button onClick={setUp} className={classes.quickMessageButton}>Set up</button>;
        }
        else{
            return <div className={classes.emptyButton}></div>;
        }
    }

    let startChat = () => {
        let newMessages = [];
        newMessages.push(
            <>
                <Row className={classes.messagesArea}>
                    <Col xl={1} lg={1}>

                    </Col>
                    <Col xl={10} lg={10}>
                        <p className={classes.userMessage}><b>Start</b></p>
                        <br/>
                    </Col>
                    <Col xl={1} lg={1} className={classes.userIcon}>
                        <img src={user}/>
                    </Col>
                </Row>
                <Row className={classes.messagesArea}>
                    <Col xl={1} lg={1} className={classes.assistantIcon}>
                        <img src={messageAssistant}/>
                    </Col>
                    <Col xl={10} lg={10}>
                        <div>

                        </div>
                        <p className={classes.assistantMessage}>I am <b>Round</b> - your Assistant! Will show you how to manage you finance and get higher cashback.</p>
                        <p className={classes.assistantMessage}>Now we need to set up the program, so you can receive the right data.</p>
                        <p className={classes.assistantMessage}>Put in your cards and money left there. Also write the amount of cash you have.</p>
                        <br/>
                    </Col>
                    <Col xl={1} lg={1}>

                    </Col>
                </Row>
            </>
        );
        setAvailableCommands("Set up");

        addMessage(newMessages);
    }

    let setUp = () => {
        let newMessages = [];
        newMessages.push(
            <>
                <Row className={classes.messagesArea}>
                    <Col xl={1} lg={1}>

                    </Col>
                    <Col xl={10} lg={10}>
                        <p className={classes.userMessage}><b>Set up</b></p>
                        <br/>
                    </Col>
                    <Col xl={1} lg={1} className={classes.userIcon}>
                        <img src={user}/>
                    </Col>
                </Row>
                <Row className={classes.messagesArea}>
                    <Col xl={1} lg={1} className={classes.assistantIcon}>
                        <img src={messageAssistant}/>
                    </Col>
                    <Col xl={10} lg={10}>
                        <p className={classes.assistantMessage}>To set up your analytics fill in your data</p>
                        <br/>
                    </Col>
                    <Col xl={1} lg={1}>

                    </Col>
                </Row>
                <Row className={classes.messagesArea}>
                    <Col xl={1} lg={1}>

                    </Col>
                    <Col xl={10} lg={10}>
                        <div className={classes.userMessage}>
                            <p>Money</p>
                            <input/>
                            <p>Cash</p>
                            <input/>
                        </div>
                        <br/>
                    </Col>
                    <Col xl={1} lg={1} className={classes.userIcon}>
                        <img src={user}/>
                    </Col>
                </Row>
            </>
        );
        setAvailableCommands("");

        addMessage(newMessages);
    }

    let sendMessage = () => {
        let newMessages = [];

        if(newMessageText != ""){
            newMessages.push(
                <>
                    <Row className={classes.messagesArea}>
                        <Col xl={1} lg={1}>

                        </Col>
                        <Col xl={10} lg={10}>
                            <p className={classes.userMessage}>{newMessageText}</p>
                            <br/>
                        </Col>
                        <Col xl={1} lg={1} className={classes.userIcon}>
                            <img src={user}/>
                        </Col>
                    </Row>
                </>
            );

            if((newMessageText == "Start") & (availableCommands == "Start")) {
                newMessages.push(
                    <>
                        <Row className={classes.messagesArea}>
                            <Col xl={1} lg={1} className={classes.assistantIcon}>
                                <img src={messageAssistant}/>
                            </Col>
                            <Col xl={10} lg={10}>
                                <div>

                                </div>
                                <p className={classes.assistantMessage}>I am <b>Round</b> - your Assistant! Will show
                                    you how to manage you finance and get higher cashback.</p>
                                <p className={classes.assistantMessage}>Now we need to set up the program, so you can
                                    receive the right data.</p>
                                <p className={classes.assistantMessage}>Put in your cards and money left there. Also
                                    write the amount of cash you have.</p>
                                <br/>
                            </Col>
                            <Col xl={1} lg={1}>

                            </Col>
                        </Row>
                    </>
                );
                setAvailableCommands("Set up");
            }
            if((newMessageText == "Set up") & (availableCommands == "Set up")) {
                newMessages.push(
                    <>
                        <Row className={classes.messagesArea}>
                            <Col xl={1} lg={1} className={classes.assistantIcon}>
                                <img src={messageAssistant}/>
                            </Col>
                            <Col xl={10} lg={10}>
                                <p className={classes.assistantMessage}>To set up your analytics fill in your data</p>
                                <br/>
                            </Col>
                            <Col xl={1} lg={1}>

                            </Col>
                        </Row>
                        <Row className={classes.messagesArea}>
                            <Col xl={1} lg={1}>

                            </Col>
                            <Col xl={10} lg={10}>
                                <div className={classes.userMessage}>
                                    <p>Money</p>
                                    <input/>
                                    <p>Cash</p>
                                    <input/>
                                </div>
                                <br/>
                            </Col>
                            <Col xl={1} lg={1} className={classes.userIcon}>
                                <img src={user}/>
                            </Col>
                        </Row>
                    </>
                );
                setAvailableCommands("");
            }
            if((newMessageText != availableCommands) & !((newMessageText == "Start") & (availableCommands == "Set up"))){
                newMessages.push(
                    <>
                        <Row className={classes.messagesArea}>
                            <Col xl={1} lg={1} className={classes.assistantIcon}>
                                <img src={messageAssistant}/>
                            </Col>
                            <Col xl={10} lg={10}>
                                <p className={classes.assistantMessage}>Do not understand you!</p>
                                <br/>
                            </Col>
                            <Col xl={1} lg={1}>

                            </Col>
                        </Row>
                    </>
                );
            }

            setNewMessageText("");
        }

        addMessage(newMessages);
    }

    let handleKeyPress = (e) => {
        if(e.key == "Enter")
            sendMessage();

    }

    return (
        <>
            <HeaderContainer/>
            <Container className={elements.pageLayout}>
                <Row>
                    <Col xl={2} lg={2} className="d-none d-lg-block">
                        <NavigationMenu/>
                    </Col>
                    <Col xl={7} lg={7} className={classes.chatLayout}>
                        <div className={classes.chatWindow}>

                            <Messages/>

                        </div>
                        <div className={classes.newMessageArea}>
                            <Button/>
                            <div className={classes.writeNewMessageArea}>
                                <input onChange={e => setNewMessageText(e.target.value)} onKeyDown={(e) => handleKeyPress(e)} value={newMessageText} placeholder={"write a message"} className={classes.newMessageInput}/>
                                <img onClick={sendMessage} src={sendMessageIcon}/>
                            </div>
                        </div>
                    </Col>
                    <Col xl={3} lg={3}>
                        <p className={classes.startMessage}>Let's start!</p>
                        <img src={assistant} className={classes.assistant}/>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default AssistantPage;