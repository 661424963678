import {Container, Nav} from "react-bootstrap";
import header from "../styles/components_styles/header.module.scss";
import elements from "../styles/elements.module.scss";
import classes from "../styles/components_styles/navigationMenu.module.scss";
import {FiHome} from "react-icons/fi";
import {AiOutlineShoppingCart} from "react-icons/ai";
import {BsGrid} from "react-icons/bs";
import {IoCellularOutline, IoSettingsOutline} from "react-icons/io5";
import React from "react";
import {NavLink} from "react-router-dom";
import {paths} from "../store/paths";

function User(props) {

    return (
        <Container className={header.notifications_layout} status={props.status}>
            <NavLink to={paths.startPage} className={`${elements.exitBtn} ${classes.exitBtn} ${elements.navLink}`}>Exit</NavLink>
        </Container>
    );
}

export default User;