import {useLayoutEffect, useState} from "react";

const isDarkTheme = window?.matchMedia('(prefers-color-scheme: dark)').matches;
const defaultSystemTheme = isDarkTheme ? 'dark' : 'white';

export const useTheme = (defaultSetTheme) => {

    let saveTheme = localStorage.getItem('app-theme');

    let defaultTheme = defaultSetTheme;

    if(saveTheme == null){
        if((defaultSetTheme == 'blue') & (defaultSystemTheme != 'dark')){
            defaultTheme = defaultSetTheme;
        }
        else{
            defaultTheme = defaultSystemTheme;
        }
    }

    if(saveTheme == 'dark'){
        defaultTheme = saveTheme;
    }

    if(saveTheme != 'dark'){
        defaultTheme = defaultSetTheme;
    }

    const [theme, setTheme] = useState(defaultTheme)

    useLayoutEffect(() => {
        document.body.setAttribute('theme', theme)
        localStorage.setItem('app-theme', theme)
    }, [theme])

    return [theme, setTheme]
}