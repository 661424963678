import classes from "../styles/choose.module.scss";
import elements from "../styles/elements.module.scss";
import '../styles/settings/background-color.scss'

import {Container} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import React, {useState} from "react";
import {shops_list} from "../store/categories_and_shops";
import {paths} from "../store/paths";
import {useTheme} from "../Hooks/useTheme";

function ShopsList(props){

    let activeShopList = shops_list[props.category];

    let first = true;
    const shopList = activeShopList.map(shop => {
        if(first){
            first = false;
            return(
                <div key={shop+"Div"}>
                    <input type="radio" name="flexRadioDefault" key={shop+"Input"} defaultChecked/>
                    <label htmlFor="flexRadioDefault1" key={shop+"Label"}>{shop}</label>
                </div>
            );
        }
        else{
            return(
                <div key={shop+"Div"}>
                    <input type="radio" name="flexRadioDefault" key={shop+"Input"}/>
                    <label htmlFor="flexRadioDefault1" key={shop+"Label"}>{shop}</label>
                </div>
            );
        }
    });

    return shopList;

}

function ChooseShopsPage() {

    const [chosenButtons, setChosenButtons] = useState({...(JSON.parse(sessionStorage.getItem("chosenCategories")))});

    if(chosenButtons == null){
        window.location.replace("/");
    }

    const [theme, setTheme] = useTheme();

    if(theme == 'dark')
        document.body.setAttribute("theme", "dark");
    else
        document.body.setAttribute("theme", "white");

    const validatorAlert = React.createRef();
    const NextButton = React.createRef();

    const categories = [];
    for (let category of Object.keys(chosenButtons)){
        categories.push(category);
    }

    let BtnList = () => {
        const list = categories.map(category => {
            return (
                <form key={category+"Form"}>
                    <button disabled={true} className={`${"chooseButton chooseButton" + category.replace(/ /g,'')} ${classes.chooseButton}`} key={category}>{category}</button>
                    <ShopsList category={category}/>
                </form>
            );
        });

        return list;
    }

    return (
        <Container className={elements.container_center}>
            <p className={classes.headerText}>Choose you favourite shops, so we can provide you <span className={classes.highlighted_text}>maximum cashback</span> there</p>
            <BtnList/>
            <NavLink ref={NextButton} to={paths.homePage} className={`${classes.button} ${elements.button} ${elements.btn_fill}`}>Finish</NavLink>
            <br/>
        </Container>
    );
}

export default ChooseShopsPage;