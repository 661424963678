import {connect} from "react-redux";
import {onNameChangeActionCreator} from "../store/action_types";
import HomePage from "../pages/HomePage";

let mapStateToProps = (state) => {
    return{
        appStore: state.appStore
    }
}

let mapDispatchToProps = (dispatch) => {
    return{
        onNameChange: (name) => {
            dispatch(onNameChangeActionCreator(name));
        }
    }
}

const HomePageContainer = connect(mapStateToProps, mapDispatchToProps)(HomePage);

export default HomePageContainer;