import {paths} from '../store/paths.js';

import React, {useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import NavigationMenu from "../components/NavigationMenu";
import classes from '../styles/settingsPage.module.scss';
import elements from "../styles/elements.module.scss";
import {NavLink} from "react-router-dom";
import {BsMoon, BsSun} from "react-icons/bs";
import {useTheme} from "../Hooks/useTheme";
import HeaderContainer from "../pageContainers/HeaderContainer";

function UserLogin(props){

    const loginInput = React.createRef();

    let onLoginChange = () => {
        props.onLoginChange(loginInput.current.value);
    }

    if(props.action == true){
        return(
            <div className={`${elements.name_area} ${classes.inputArea}`}>
                <input onChange={onLoginChange} ref={loginInput} type="text" placeholder="login" defaultValue={props.login} className={`${elements.name_input} ${classes.inputText}`}/>
            </div>
        );
    }else{
        return(
            <p className={classes.downText}>{props.login}</p>
        );
    }
}

function UserName(props){

    const nameInput = React.createRef();

    let onNameChange = () => {
        props.onNameChange(nameInput.current.value);
    }

    if(props.action == true){
        return(
            <div className={`${elements.name_area} ${classes.inputArea}`}>
                <input onChange={onNameChange} ref={nameInput} type="text" placeholder="name" defaultValue={props.name} className={`${elements.name_input} ${classes.inputText}`}/>
            </div>
        );
    }else{
        return(
            <p className={classes.downText}>{props.name}</p>
        );
    }
}

function ChangeThemeButton(props){
    if(props.theme == 'dark')
        return <button onClick={props.handler} theme={props.theme} className={classes.themeBtn}><BsSun className={classes.themeIcon}/>Light Theme</button>;
    else
        return <button onClick={props.handler} theme={props.theme} className={classes.themeBtn}><BsMoon className={classes.themeIcon}/>Dark Theme</button>;
}

function SettingsPage(props) {

    const [theme, setTheme] = useTheme('blue');

    const [editingLogin, setEditingLogin] = useState(false);
    const [editingName, setEditingName] = useState(false);
    const [buttonsText, setButtonsText] = useState(
        ["Edit", "Edit"]
    );

    let changeAppTheme = () => {
        if(theme == 'dark') {
            setTheme('blue');
        }
        else {
            setTheme('dark')
        }
    }

    let editLogin = (e) => {
        if(editingLogin == false) {
            buttonsText[0] = "Save";
            e.target.setAttribute("edit", "true");
        }
        else{
            buttonsText[0] = "Edit";
            e.target.setAttribute("edit", "false");
        }
        setEditingLogin(!editingLogin);
    }

    let editName = (e) => {
        if(editingName == false) {
            buttonsText[1] = "Save";
            e.target.setAttribute("edit", "true");
        }
        else{
            buttonsText[1] = "Edit";
            e.target.setAttribute("edit", "false");
        }
        setEditingName(!editingName);
    }

    return (
        <>
            <HeaderContainer/>
            <Container className={elements.pageLayout}>
                <Row>
                    <Col xl={2} lg={2} className="d-none d-lg-block">
                        <NavigationMenu/>
                    </Col>
                    <Col xl={10} lg={10} md={12} sm={12} xs={12} className={classes.settingsLayout}>
                        <div>
                            <p>Login</p>
                            <UserLogin action={editingLogin} onLoginChange={props.onLoginChange} login={props.appStore.login}/>
                            <button onClick={(e) => {editLogin(e)}} className={classes.editButton}>{buttonsText[0]}</button>
                        </div>
                        <div>
                            <p>Name</p>
                            <UserName action={editingName} onNameChange={props.onNameChange} name={props.appStore.name}/>
                            <button onClick={(e) => {editName(e)}} className={classes.editButton}>{buttonsText[1]}</button>
                        </div>
                        <div>
                            <p>App Theme</p>
                            <ChangeThemeButton handler={changeAppTheme} theme={theme}/>
                        </div>
                        <div>
                            <NavLink to={paths.startPage} className={`${elements.exitBtn} ${elements.navLink} ${classes.exitBtn}`}>Exit</NavLink>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default SettingsPage;