import SettingsPage from "../pages/SettingsPage";
import {connect} from "react-redux";
import {onLoginChangeActionCreator, onNameChangeActionCreator} from "../store/action_types";

let mapStateToProps = (state) => {
    return{
        appStore: state.appStore
    }
}

let mapDispatchToProps = (dispatch) => {
    return{
        onLoginChange: (login) => {
            dispatch(onLoginChangeActionCreator(login));
        },
        onNameChange: (name) => {
            dispatch(onNameChangeActionCreator(name));
        }
    }
}

const SettingsPageContainer = connect(mapStateToProps, mapDispatchToProps)(SettingsPage);

export default SettingsPageContainer;