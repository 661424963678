import {connect} from "react-redux";
import Header from "../components/Header";

let mapStateToProps = (state) => {
    return{
        appStore: state.appStore
    }
}

const HeaderContainer = connect(mapStateToProps)(Header);

export default HeaderContainer;