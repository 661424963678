import {Container} from "react-bootstrap";
import {FiHome} from "react-icons/fi";
import {AiOutlineShoppingCart} from "react-icons/ai";
import {BsGrid} from "react-icons/bs";
import {IoCellularOutline, IoSettingsOutline} from "react-icons/io5";
import React from "react";
import elements from "../styles/elements.module.scss";
import classes from "../styles/components_styles/navigationMenu.module.scss";
import header from "../styles/components_styles/header.module.scss";
import {NavLink} from "react-router-dom";
import {paths} from "../store/paths";

function Notifications(props) {

    return (
        <Container className={header.notifications_layout} status={props.status}>
            <NavLink to={paths.homePage} className={`${elements.button} ${classes.menu_btn} ${classes.menu_btn_active}`}>
                <FiHome className={classes.menu_logo}/>
                Home
            </NavLink>
            <NavLink to={paths.shopsPage} className={`${elements.button} ${classes.menu_btn}`}>
                <AiOutlineShoppingCart className={classes.menu_logo}/>
                Shops
            </NavLink>
            <NavLink to={paths.categoriesPage} className={`${elements.button} ${classes.menu_btn}`}>
                <BsGrid className={classes.menu_logo}/>
                Categories
            </NavLink>
            <NavLink to={paths.assistantPage} className={`${elements.button} ${classes.menu_btn}`}>
                <IoCellularOutline className={classes.menu_logo}/>
                Assistant
            </NavLink>
            <NavLink to={paths.settingsPage} className={`${elements.button} ${classes.menu_btn}`}>
                <IoSettingsOutline className={classes.menu_logo}/>
                Settings
            </NavLink>
        </Container>
    );
}

export default Notifications;