import classes from '../styles/startPage.module.scss';
import elements from '../styles/elements.module.scss';
import '../styles/settings/background-color.scss';
import {useTheme} from "../Hooks/useTheme";

function BigLogo(props) {

    const [theme, setTheme] = useTheme('white');

    return (
        <>
            <h1 className={`${elements.big_logo} ${classes.big_logo}`}>{props.title}</h1>
        </>
    );
}

export default BigLogo;