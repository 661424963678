import elements from "../styles/elements.module.scss";

import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import NavigationMenu from "../components/NavigationMenu";
import MyCategories from "../components/MyCategories";
import HeaderContainer from "../pageContainers/HeaderContainer";

function CategoriesPage() {

    return (
        <>
            <HeaderContainer/>
            <Container className={elements.pageLayout}>
                <Row>
                    <Col xl={2} lg={2} className="d-none d-lg-block">
                        <NavigationMenu/>
                    </Col>
                    <Col xl={10} lg={10} md={12} sm={12} xs={12}>
                        <MyCategories/>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default CategoriesPage;