import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/app.scss';

import {paths} from "./store/paths";

import {Route, Routes} from "react-router-dom";

import StartPage from "./pages/StartPage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import ChooseCategoriesPage from "./pages/ChooseCategoriesPage";
import ChooseShopsPage from "./pages/ChooseShopsPage";
import ShopsPage from "./pages/ShopsPage";
import CategoriesPage from "./pages/CategoriesPage";
import AssistantPage from "./pages/AssistantPage";
import SettingsPageContainer from "./pageContainers/SettingsPageContainer";
import HomePageContainer from "./pageContainers/HomePageContainer";

function App() {
  return (
      <Routes>
          <Route path={paths.startPage} element={<StartPage />} />
          <Route path={paths.loginPage} element={<LoginPage />} />
          <Route path={paths.registerPage} element={<RegisterPage />}/>

          <Route path={paths.chooseCategoriesPage} element={<ChooseCategoriesPage />} />
          <Route path={paths.chooseShopsPage} element={<ChooseShopsPage />} />

          <Route path={paths.homePage} element={<HomePageContainer />} />
          <Route path={paths.shopsPage} element={<ShopsPage />} />
          <Route path={paths.categoriesPage} element={<CategoriesPage />} />
          <Route path={paths.assistantPage} element={<AssistantPage />} />
          <Route path={paths.settingsPage} element={<SettingsPageContainer />} />

          <Route path={paths.undefined} element={<StartPage />} />
      </Routes>
  );
}

export default App;
