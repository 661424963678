import React, {useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import NavigationMenu from "../components/NavigationMenu";
import elements from "../styles/elements.module.scss";
import classes from "../styles/shopsPage.module.scss";
import {shopsPage} from "../store/categories_and_shops";
import HeaderContainer from "../pageContainers/HeaderContainer";
import star from '../images/star.svg';
import foodIcon from "../images/categoriesPage_icons/WhiteIcon_Food.svg";
import electronicsIcon from "../images/categoriesPage_icons/WhiteIcon_Electronics.svg";
import carIcon from "../images/categoriesPage_icons/WhiteIcon_Car.svg";
import medicineIcon from "../images/categoriesPage_icons/WhiteIcon_Medicine.svg";
import gasIcon from "../images/categoriesPage_icons/WhiteIcon_Gas.svg";
import shopIcon from "../images/categoriesPage_icons/WhiteIcon_Shop.svg";
import petsIcon from "../images/categoriesPage_icons/WhiteIcon_Pets.svg";

function ShopsPage() {

    const [categoriesIconsList, setCategoriesIconsList] = useState([
        foodIcon,
        electronicsIcon,
        carIcon,
        medicineIcon,
        gasIcon,
        shopIcon,
        petsIcon
    ]);

    const [sectionType, setSectionType] = useState('shops');

    const shopsBtn = React.createRef();
    const promotionsBtn = React.createRef();

    let CategoriesIcons = () => {
        let list = categoriesIconsList.map(icon => {
            return <img src={icon} className={classes.categories} key={icon}/>;
        });
        return list;
    }

    let ShopsLayout = () => {

        let layout = [];

        Object.keys(shopsPage).forEach(function(category) {

            let list = [];

            Object.values(shopsPage[category]).forEach(function(shop) {

                let stars = [];

                for(let starCount = 1; starCount <= shop["stars"]; starCount++){
                    stars.push(
                        <>
                            <img src={star} className={classes.starsRow}/>
                        </>
                    );
                }


                list.push(
                    <div className={classes.shop}>
                        <img src={shop["image"]} className={classes.shopImg}/>
                        <div className={classes.shopStars}>
                            {stars}
                            <p className={classes.starsRow}>{shop["stars"]}</p>
                        </div>
                    </div>
                );
            });

            layout.push(
                <div className={classes.categoryLayout}>
                    <h5>{category}</h5>
                    {list}
                </div>
            );
        });

        return layout;
    }

    let onSectionChange = (e) => {
        if((shopsBtn.current.getAttribute("status") == "active") & (e.target.getAttribute("status") != "active")){
            shopsBtn.current.setAttribute("status", "inactive");
            promotionsBtn.current.setAttribute("status", "active");
            setSectionType('promotions');
        }if((promotionsBtn.current.getAttribute("status") == "active") & (e.target.getAttribute("status") != "active")) {
            shopsBtn.current.setAttribute("status", "active");
            promotionsBtn.current.setAttribute("status", "inactive");
            setSectionType('shops');
        }
    }

    let SectionLayout = (props) => {
        if(props.type == 'shops') {
            return <ShopsLayout/>;
        }
        if(props.type == 'promotions'){
            return (
                <>
                    <div className={classes.categoriesRow}>
                        <CategoriesIcons/>
                    </div>
                </>
            );
        }
    }

    return (
        <>
            <HeaderContainer/>
            <Container className={elements.pageLayout}>
                <Row>
                    <Col xl={2} lg={2} className="d-none d-lg-block">
                        <NavigationMenu/>
                    </Col>
                    <Col xl={10} lg={10} md={12} sm={12} xs={12}>
                        <Row>
                            <Col>
                                <button status={"active"}  ref={shopsBtn} onClick={(e) => {onSectionChange(e)}} className={elements.underline_btn}>Shops</button>
                                <button status={"inactive"} ref={promotionsBtn} onClick={(e) => {onSectionChange(e)}} className={elements.underline_btn}>Promotions</button>
                            </Col>
                        </Row>
                        <Row>
                            <SectionLayout type={sectionType}/>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default ShopsPage;