import {initialState} from "./initialState";
import {CHANGE_LOGIN, CHANGE_NAME} from "./action_types";

const appPageReducer = (state = initialState.appStore, action) => {
    let stateCopy = {...state};
    switch (action.type){
        case CHANGE_LOGIN:
            stateCopy.login = action.newLogin;
            return stateCopy;
            break;
        case CHANGE_NAME:
            stateCopy.name = action.newName;
            return stateCopy;
            break;
        default:
            return state;
    }
}

export default appPageReducer;