import classes from "../styles/components_styles/header.module.scss";
import elements from "../styles/elements.module.scss";

import {Col, Container, Row} from "react-bootstrap";
import {TfiSearch} from "react-icons/tfi";
import {MdOutlineKeyboardArrowDown} from "react-icons/md";
import React, {useState} from "react";

import notifications from '../images/notifications.svg';
import avatar from '../images/avatar.svg';
import Notifications from "./Notifications";
import getDate from "../Hooks/getDate";
import Menu from "./Menu";
import User from "./User";
import {useTheme} from "../Hooks/useTheme";

function Header(props) {

    const [theme, setTheme] = useTheme('blue');

    const [currentNotificationsStatus, setCurrentNotificationsStatus] = useState("inactive");
    const [currentMenuStatus, setCurrentMenuStatus] = useState("inactive");
    const [currentUserStatus, setCurrentUserStatus] = useState("inactive");

    const date = getDate(0, 0);

    const blackout = React.createRef();

    let inactiveAll = () => {
        blackout.current.setAttribute("status", "inactive");
        setCurrentNotificationsStatus("inactive");
        setCurrentMenuStatus("inactive");
        setCurrentUserStatus("inactive");
    }

    let onNotificationsClick = () => {
        if(blackout.current.getAttribute("status") == "inactive") {
            blackout.current.setAttribute("status", "active");
            setCurrentNotificationsStatus("active");
        } else {
            inactiveAll();
        }
    }

    let onOpenMenuClick = () => {
        if(blackout.current.getAttribute("status") == "inactive") {
            blackout.current.setAttribute("status", "active");
            setCurrentMenuStatus("active");
        } else {
            inactiveAll();
        }
    }

    let onUserClick = () => {
        if(blackout.current.getAttribute("status") == "inactive") {
            blackout.current.setAttribute("status", "active");
            setCurrentUserStatus("active");
        } else {
            inactiveAll();
        }
    }

    return (
        <>
            <div onClick={onNotificationsClick}  ref={blackout} status={"inactive"} className={classes.blackout}></div>
            <Container className={classes.layout}>
                <Row>
                    <Col xs={2} className="d-block d-sm-none">
                        <img className={classes.avatar} src={avatar}/>
                    </Col>
                    <Col xl={4} lg={4} md={4} sm={4} className="d-none d-sm-block">
                        <h2 className={classes.logo}>Smart Pay</h2>
                    </Col>
                    <Col xl={3} lg={4} md={5} sm={5} xs={7}>
                        <div className={classes.search_area}>
                            <TfiSearch className={elements.input_logo}/>
                            <input type="text" placeholder="search" className={classes.search_input}/>
                        </div>
                    </Col>
                    <Col xl={5} lg={4} md={3} sm={3} xs={3}>
                        <Row className={classes.menu_info}>
                            <Col xl={5} className="d-none d-xl-block">
                                <p className={classes.date}>{date}</p>
                            </Col>
                            <Col xl={2} lg={4} md={4} sm={2} xs={6}>
                                <img onClick={onNotificationsClick} className={classes.notifications} src={notifications}/>
                            </Col>
                            <Col onClick={onUserClick} xl={2} lg={3} className="d-none d-lg-block">
                                <p className={classes.user}>{props.appStore.login}</p>
                            </Col>
                            <Col onClick={onUserClick} xl={1} lg={1} className="d-none d-lg-block">
                                <p className={classes.userArrow}><MdOutlineKeyboardArrowDown/></p>
                            </Col>
                            <Col sm={5} xs={6} md={4} className="d-block d-lg-none">
                                <MdOutlineKeyboardArrowDown onClick={onOpenMenuClick} className={classes.arrowMenu}/>
                            </Col>
                            <Col xl={2} lg={4} md={4} sm={4} className="d-none d-sm-block">
                                <img className={classes.avatar} src={avatar}/>
                            </Col>

                        </Row>
                    </Col>
                </Row>
                <Notifications status={currentNotificationsStatus}/>
                <Menu status={currentMenuStatus}/>
                <User status={currentUserStatus}/>
            </Container>
        </>
    );
}

export default Header;