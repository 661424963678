import {Col, Row} from "react-bootstrap";

import classes from "../styles/components_styles/purchase.module.scss";
import '../styles/components_styles/purchaseType.scss'

function Purchase(props) {

    let DateText = (date) => {
        if(date.date == "processing...")
            return <p className={`${classes.purchaseDate} ${classes.processingData}`}>{props.date}</p>;
        else
            return <p className={classes.purchaseDate}>{props.date}</p>;
    }

    return (
        <Row>
            <Col lg={4} md={4} sm={4} xs={5}>
                <Row>
                    <Col lg={2} md={2} sm={2} xs={2}>
                        <img className={classes.img_icon} src={props.img_src}/>
                    </Col>
                    <Col lg={10} md={10} sm={10} xs={10}>
                        <p className={classes.purchaseTitle}>{props.title}</p>
                        <DateText date={props.date}/>
                    </Col>
                </Row>
            </Col>
            <Col lg={3} md={3} sm={3} xs={4}>
                <button disabled={true} className={`${classes.purchaseType} ${"purchaseType" + props.type}`}>● {props.type}</button>
            </Col>
            <Col lg={1} md={1} sm={1} xs={1}>
                <img src={props.arrow_src} className={classes.purchaseArrow}/>
            </Col>
            <Col lg={4} md={4} sm={4} xs={2}>
                <Row>
                    <Col className={classes.purchaseOverflow}>
                        <p className={classes.purchaseAmount}>{props.amount}</p>
                    </Col>
                    <Col className="d-none d-sm-block">
                        <button className={classes.purchaseDetails}>Details</button>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default Purchase;