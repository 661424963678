import classes from '../styles/startPage.module.scss';
import elements from '../styles/elements.module.scss';

import {Container} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import {paths} from "../store/paths";
import BigLogo from "../components/BigLogo";

function StartPage() {

    return (
        <Container className={elements.container_center}>
            <BigLogo title={"Smart Pay"}/>
            <NavLink to={paths.loginPage} className={`${classes.button} ${elements.button} ${elements.btn_fill}`}>Login</NavLink>
            <NavLink to={paths.registerPage} className={`${classes.button} ${elements.button} ${elements.btn_empty}`}>Register</NavLink>
        </Container>
    );
}

export default StartPage;