import {Col, Row} from "react-bootstrap";
import classes from "../styles/homePage.module.scss";
import React from "react";
import img1 from "../images/home_img1.svg";
import img2 from "../images/home_img2.svg";
import img3 from "../images/home_img3.svg";

function HorizontalAds() {

    return (
        <Row>
            <Col lg={12} md={12} sm={12} xs={12} className={classes.images}>
                <img src={img1}/>
            </Col>
            <Col lg={6} md={6} sm={6} xs={6} className={classes.images}>
                <img src={img2}/>
            </Col>
            <Col lg={6} md={6} sm={6} xs={6} className={classes.images}>
                <img className={classes.smallerImg} src={img3}/>
            </Col>
        </Row>
    );
}

export default HorizontalAds;