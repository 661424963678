import React from "react";
import '../styles/components_styles/categoriesButtons.scss'
import {myCategories_list} from "../store/categories_and_shops";
import {IoCloseCircleOutline} from "react-icons/io5";
import classes from "../styles/components_styles/myCategories.module.scss";

function CategoriesList(){

    let choosedButton = false;

    let onToggleClick = (e) => {
        if(e.target.getAttribute("choosed") == "false")
            e.target.setAttribute("choosed", "true");
        else
            e.target.setAttribute("choosed", "false");
    }

    let Categories = (category) => {
        choosedButton = !choosedButton;
        return(
            <div className={classes.category}>
                <img className={classes.inlineElement} src={myCategories_list[category]}/>
                <button disabled={true} className={`${"chooseButton chooseButton" + category.replace(/ /g,'')} ${classes.inlineElement}`}>{category}</button>
                <IoCloseCircleOutline onClick={(e) => {onToggleClick(e)}} choosed={choosedButton.toString()} className={`${classes.inlineElement} ${classes.toggleBtn}`}/>
            </div>
        );
    }

    let categoriesList = [];
    for(let category in myCategories_list){
        categoriesList.push(Categories(category));
    }
    return categoriesList;
}

function MyCategories() {
    return(
        <div className={classes.categoriesList}>
            <CategoriesList/>
        </div>
    );
}

export default MyCategories;