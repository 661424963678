import {Col, Container, Row} from "react-bootstrap";
import classes from "../styles/homePage.module.scss";
import elements from "../styles/elements.module.scss";
import React, {useState} from "react";
import NavigationMenu from "../components/NavigationMenu";

import avatar from '../images/big_avatar.svg';
import changeButton from '../images/changeButton.svg';
import card from '../images/card.svg';
import food_icon from '../images/food_icon.svg';
import taxi_icon from '../images/taxi_icon.svg';
import money_icon from '../images/money_icon.svg';
import transfer_icon from '../images/transfer_icon.svg';
import shop_icon from '../images/shop_icon.svg';
import arrow_up from '../images/arrow_up.svg';
import arrow_down from '../images/arrow_down.svg';
import tick from '../images/tick.png';
import Purchase from "../components/Purchase";
import getDate from "../Hooks/getDate";
import VerticalAds from "../components/VerticalAds";
import HorizontalAds from "../components/HorizontalAds";
import AssistantMessage from "../components/AssistantMessage";
import HeaderContainer from "../pageContainers/HeaderContainer";

function HomePage(props) {

    const [isChangingName, setIsChangingName] = useState(false);

    const nameInput = React.createRef();

    const today = getDate(0, 0);
    const yesterday = getDate(1, 0);
    const prevDay = getDate(2, 0);

    const all_purchases = React.createRef();
    const upcoming_purchases = React.createRef();
    const all_purchases_layout = React.createRef();
    const upcoming_purchases_layout = React.createRef();
    const changeButtonIcon = React.createRef();

    let onChangePurchases = (e) => {
        if((all_purchases.current.getAttribute("status") == "active") & (e.target.getAttribute("status") != "active")){
            all_purchases.current.setAttribute("status", "inactive");
            upcoming_purchases.current.setAttribute("status", "active");
            all_purchases_layout.current.setAttribute("status", "inactive");
            upcoming_purchases_layout.current.setAttribute("status", "active");
        }if((upcoming_purchases.current.getAttribute("status") == "active") & (e.target.getAttribute("status") != "active")) {
            all_purchases.current.setAttribute("status", "active");
            upcoming_purchases.current.setAttribute("status", "inactive");
            all_purchases_layout.current.setAttribute("status", "active");
            upcoming_purchases_layout.current.setAttribute("status", "inactive");
        }
    }

    let UserName = () => {
        if(isChangingName)
            return (
                <div className={elements.name_area}>
                    <input type="text" placeholder="name" defaultValue={props.appStore.name} ref={nameInput} className={`${elements.name_input} ${classes.nameText}`}/>
                </div>
            );
        else
            return <p className={classes.nameText}>{props.appStore.name}</p>;
    }

    let changeName = () => {
        if(isChangingName){
            props.onNameChange(nameInput.current.value);
            changeButtonIcon.current.src = changeButton;
        }else{
            changeButtonIcon.current.src = tick;
        }
        setIsChangingName(!isChangingName);
    }

    return (
        <>
            <HeaderContainer/>
            <Container className={elements.pageLayout}>
                <Row>
                    <Col xl={{ span: 2, order: 1 }} lg={{ span: 2, order: 1 }} className="d-none d-lg-block">
                        <NavigationMenu/>
                        <Col className="d-none d-lg-block d-xl-none">
                            <VerticalAds/>
                        </Col>
                    </Col>
                    <Col md={{ span: 12, order: 1 }} className="d-block d-lg-none">
                        <HorizontalAds/>
                    </Col>
                    <Col md={{ span: 12, order: 2 }} className={`${classes.assistantLayout} ${"d-block d-lg-none"}`}>
                        <AssistantMessage/>
                    </Col>
                    <Col xl={{ span: 5, order: 2 }} lg={{ span: 6, order: 2 }} md={{ span: 12, order: 3 }} sm={{ span: 12, order: 3 }} xs={{ span: 12, order: 3 }}>
                        <div className="d-none d-lg-block">
                            <HorizontalAds/>
                        </div>
                        <Row>
                            <Col>
                                <h5 className={classes.purchasesHeader}>Purchases</h5>
                                <div>
                                    <button status={"active"} onClick={onChangePurchases} ref={all_purchases} className={elements.underline_btn}>All</button>
                                    <button status={"inactive"} onClick={onChangePurchases} ref={upcoming_purchases} className={elements.underline_btn}>Upcoming</button>
                                </div>
                                <div status={"active"} className={classes.purchasesLayout} ref={all_purchases_layout}>
                                    <p className={classes.date}>{yesterday}</p>
                                    <Purchase img_src={food_icon} title={"Walmart"} date={yesterday + " at 20:21"} type={"Food"} arrow_src={arrow_down} amount={"-107$"}/>
                                    <hr/>
                                    <Purchase img_src={taxi_icon} title={"Uber"} date={yesterday + " at 12:34"} type={"Transport"} arrow_src={arrow_down} amount={"-11$"}/>
                                    <hr/>
                                    <Purchase img_src={money_icon} title={"Salary"} date={yesterday + " at 9:38"} type={"Income"} arrow_src={arrow_up} amount={"+3000$"}/>
                                    <p className={classes.date}>{prevDay}</p>
                                    <Purchase img_src={transfer_icon} title={"Elon Mask"} date={prevDay + " at 17:01"} type={"Income"} arrow_src={arrow_up} amount={"+1234$"}/>
                                    <hr/>
                                    <Purchase img_src={shop_icon} title={"Shein"} date={prevDay + " at 11:42"} type={"Shops"} arrow_src={arrow_down} amount={"-550$"}/>
                                </div>
                                <div status={"inactive"} className={classes.purchasesLayout} ref={upcoming_purchases_layout}>
                                    <p className={classes.date}>{today}</p>
                                    <Purchase img_src={taxi_icon} title={"Uber"} date={"processing..."} type={"Transport"} arrow_src={arrow_down} amount={"-25$"}/>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xl={{ span: 5, order: 3 }} lg={{ span: 4, order: 2 }} md={{ span: 12, order: 2 }}>
                        <div className={"d-none d-lg-block"}>
                            <Row className={classes.avatarLayout}>
                                <Col xl={6} lg={6} className="d-none d-xl-block">
                                    <VerticalAds/>
                                </Col>
                                <Col xl={6} lg={6} className={classes.avatar_background}>
                                    <img src={avatar}/>
                                    <img onClick={changeName} ref={changeButtonIcon} src={changeButton} className={classes.changeButton}/>
                                    <UserName/>
                                    <p className={classes.cardText}>Your card:</p>
                                    <img className={classes.card} src={card}/>
                                </Col>
                            </Row>
                        </div>

                        <Row className={classes.onlyCard}>
                            <Col className="d-block d-lg-none">
                                <h5>Your Card</h5>
                                <img className={classes.card} src={card}/>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12} md={12} className={`${classes.assistantLayout} ${"d-none d-lg-block"}`}>
                                <AssistantMessage/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default HomePage;