import classes from "../styles/components_styles/navigationMenu.module.scss";
import {FiHome} from "react-icons/fi";
import {AiOutlineShoppingCart} from "react-icons/ai";
import {BsGrid} from "react-icons/bs";
import {IoCellularOutline, IoSettingsOutline} from "react-icons/io5";
import React from "react";
import {NavLink} from "react-router-dom";
import {paths} from "../store/paths";

function Header() {

    return (
        <>
            <NavLink end to={paths.homePage} className={({isActive}) => (isActive ? classes.menu_btn_active : classes.menu_btn_inactive)}>
                <FiHome className={classes.menu_logo}/>
                Home
            </NavLink>
            <NavLink to={paths.shopsPage} className={({isActive}) => (isActive ? classes.menu_btn_active : classes.menu_btn_inactive)}>
                <AiOutlineShoppingCart className={classes.menu_logo}/>
                Shops
            </NavLink>
            <NavLink to={paths.categoriesPage} className={({isActive}) => (isActive ? classes.menu_btn_active : classes.menu_btn_inactive)}>
                <BsGrid className={classes.menu_logo}/>
                Categories
            </NavLink>
            <NavLink to={paths.assistantPage} className={({isActive}) => (isActive ? classes.menu_btn_active : classes.menu_btn_inactive)}>
                <IoCellularOutline className={classes.menu_logo}/>
                Assistant
            </NavLink>
            <NavLink to={paths.settingsPage} className={({isActive}) => (isActive ? classes.menu_btn_active : classes.menu_btn_inactive)}>
                <IoSettingsOutline className={classes.menu_logo}/>
                Settings
            </NavLink>
        </>
    );
}

export default Header;