import classes from '../styles/choose.module.scss';
import elements from "../styles/elements.module.scss";
import '../styles/settings/background-color.scss'
import '../styles/components_styles/categoriesButtons.scss'
import React from 'react';

import {Container} from "react-bootstrap";
import {createRef, useState} from "react";
import {NavLink} from "react-router-dom";
import '../store/categories_and_shops';
import {categories_list} from "../store/categories_and_shops";
import {paths} from "../store/paths";
import {useTheme} from "../Hooks/useTheme";

function ChooseCategoriesPage() {

    const [theme, setTheme] = useTheme();

    if(theme == 'dark')
        document.body.setAttribute("theme", "dark");
    else
        document.body.setAttribute("theme", "white");

    const validatorAlert = React.createRef();
    const NextButton = React.createRef();

    let [chosenButtons, setChosenButtons] = useState({
        "Food": "false",
        "Electronics": "false",
        "Auto products": "false",
        "Medicine": "false",
        "Gas stations": "false",
        "Clothes": "false",
        "Pets": "false"
    });

    let checkButtonCount = () => {
        let count = 0;
        for (let activeButton of Object.values(chosenButtons)){
            if(activeButton == "true")
                count++;
        }
        return count;
    }

    let chooseCategory = (value) => {
        let count = checkButtonCount();
        if((chosenButtons[value] != "true") & (count <= 2)){
            let newChosenButtons = {...chosenButtons};
            newChosenButtons[value] = "true";
            setChosenButtons(newChosenButtons);
            return 0;
        }
        if(chosenButtons[value] == "true"){
            let newChosenButtons = {...chosenButtons};
            newChosenButtons[value] = "false";
            setChosenButtons(newChosenButtons);
            return 0;
        }
    }

    let onNextBtnClick = (e) => {
        let count = checkButtonCount();
        if(count >= 1){
            let chosenCategories;
            for (let chosenButton of Object.entries(chosenButtons)){
                if(chosenButton[1] == "true") {
                    let newChosenCategories = {...chosenCategories};
                    newChosenCategories[chosenButton[0]] = chosenButton[1];
                    chosenCategories = {...newChosenCategories};
                }
            }
            sessionStorage.setItem("chosenCategories", JSON.stringify(chosenCategories))
        }else{
            validatorAlert.current.setAttribute("show", "true");
            NextButton.current.setAttribute("validation", "invalid");
            e.preventDefault();
        }
    }

    let BtnList = () => {
        const list = categories_list.map(value => {
            return <button onClick={() => chooseCategory(value)} active={chosenButtons[value]} className={"chooseButton chooseButton" + value.replace(/ /g,'')} key={value}>{value}</button>;
        });
        return list;
    }

    return (
        <Container className={elements.container_center}>
            <p className={classes.headerText}>Choose <span className={classes.highlighted_text}>up to 3</span> your favourite categories</p>
            <BtnList/>
            <NavLink ref={NextButton} to={paths.chooseShopsPage} onClick={onNextBtnClick} className={`${classes.button} ${elements.button} ${elements.btn_empty}`}>Next</NavLink>
            <p ref={validatorAlert} className={classes.validatorAlert}>Choose at least 1 category!</p>
        </Container>
    );
}

export default ChooseCategoriesPage;