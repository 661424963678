import foodIcon from '../images/categoriesPage_icons/WhiteIcon_Food.svg';
import electronicsIcon from '../images/categoriesPage_icons/WhiteIcon_Electronics.svg';
import carIcon from '../images/categoriesPage_icons/WhiteIcon_Car.svg';
import medicineIcon from '../images/categoriesPage_icons/WhiteIcon_Medicine.svg';
import gasIcon from '../images/categoriesPage_icons/WhiteIcon_Gas.svg';
import shopIcon from '../images/categoriesPage_icons/WhiteIcon_Shop.svg';
import petsIcon from '../images/categoriesPage_icons/WhiteIcon_Pets.svg';
import shop1 from '../images/shopsPage/shopsPage_shop1.svg';
import shop2 from '../images/shopsPage/shopsPage_shop2.svg';
import shop3 from '../images/shopsPage/shopsPage_shop3.svg';
import shop4 from '../images/shopsPage/shopsPage_shop4.svg';
import shop5 from '../images/shopsPage/shopsPage_shop5.svg';
import shop6 from '../images/shopsPage/shopsPage_shop6.svg';

export const categories_list = [
    "Food",
    "Electronics",
    "Auto products",
    "Medicine",
    "Gas stations",
    "Clothes",
    "Pets"
];

export const shops_list = {
    "Food": ["Walmart", "Kroger", "Target"],
    "Electronics": ["Best Buy",  "Amazon", "Dell"],
    "Auto products": ["Advance Auto Parts", "NAPA Auto Parts"],
    "Medicine": ["Walgreens", "CVS"],
    "Gas stations": ["Circle K", "Exxon", "Quick Stop"],
    "Clothes": ["H&M", "Levi's", "Joe's Jeans"],
    "Pets": ["PetSmart", "Petco"]
}

export const myCategories_list = {
    "Food": foodIcon,
    "Electronics": electronicsIcon,
    "Auto products": carIcon,
    "Medicine": medicineIcon,
    "Gas stations": gasIcon,
    "Clothes": shopIcon,
    "Pets": petsIcon
}

export const shopsPage = {
    "Food": {
        "shop1": {
            "image": shop1,
            "stars": 3
        },
        "shop2": {
            "image": shop2,
            "stars": 4
        },
        "shop3": {
            "image": shop3,
            "stars": 5
        }
    },
    "Gas stations": {
        "shop1": {
            "image": shop4,
            "stars": 3
        }
    },
    "Clothes": {
        "shop1": {
            "image": shop5,
            "stars": 5
        },
        "shop2": {
            "image": shop6,
            "stars": 4
        }
    }
}