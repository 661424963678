import classes from "../styles/homePage.module.scss";
import React from "react";
import assistant from "../images/assistant.svg";
import {NavLink} from "react-router-dom";
import {paths} from "../store/paths";

function AssistantMessage() {

    return (
        <>
            <div className={classes.assistantMessage}>
                <p className={classes.messageText}>I am Round - your <span>Assistant</span>! Will show you how to manage you finance and get higher cashback.</p>
                <NavLink to={paths.assistantPage} className={classes.assistantButton}>Start</NavLink>
            </div>
            <img className={classes.assistant} src={assistant}/>
        </>
    );
}

export default AssistantMessage;