import classes from "../styles/homePage.module.scss";
import {Col, Row} from "react-bootstrap";
import img4 from "../images/home_img4.svg";
import img5 from "../images/home_img5.png";
import img6 from "../images/home_img6.png";
import React from "react";

function VerticalAds() {

    return (
        <Row>
            <Col xl={12} lg={12} md={12} className={`${classes.images} ${classes.blocks}`}>
                <img src={img4}/>
            </Col>
            <Col xl={12} lg={12} md={12} className={`${classes.images} ${classes.blocks}`}>
                <img src={img5}/>
            </Col>
            <Col xl={12} lg={12} md={12} className={`${classes.images} ${classes.blocks}`}>
                <img src={img6}/>
            </Col>
        </Row>
    );
}

export default VerticalAds;