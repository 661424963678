function getDate(daysBefore, monthsBefore){
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const current = new Date();

    let days = current.getDate();
    let months= current.getMonth();
    let years = current.getFullYear();

    if(days <= daysBefore){
        months--;
        daysBefore = daysBefore - days;
        if(days-daysBefore<0){
            days = (days-daysBefore) * -1;
        }
    }

    if(months <= monthsBefore){
        years--;
        monthsBefore = monthsBefore - months;
        if(months-monthsBefore<0){
            months = (days-monthsBefore) * -1;
        }
    }

    else
        return `${monthNames[months-monthsBefore]} ${days-daysBefore}, ${years}`;

    return `${monthNames[months]} ${days}, ${years}`;
}

export default getDate;