export const paths = {
  'startPage': '/',
  'loginPage': '/login',
  'registerPage': '/register',
  'chooseCategoriesPage': '/choose_categories',
  'chooseShopsPage': '/choose_shops',
  'homePage': '/home',
  'shopsPage': '/shops',
  'categoriesPage': '/categories',
  'assistantPage': '/assistant',
  'settingsPage': '/settings',
  'undefined': '*'
}